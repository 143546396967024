/* eslint-env jquery */

"use strict";

window.addEventListener("load", () => {
    const Swiper = require("swiper").default;
    const Navigation = require("swiper").Navigation;
    const Pagination = require("swiper").Pagination;

    if (!window.up1ItemGlideInitialized) {
        const carousels = $(".up-1-item-carousel-swiper");

        carousels.each(function (index, carousel) {
            const selector = "." + $(carousel).data().id + " .swiper";
            const prevButton = `${selector} .swiper-button-prev`;
            const nextButton = `${selector} .swiper-button-next`;
            const paginationSelector = "." + $(carousel).data().id + " .swiper-pagination";

            // eslint-disable-next-line no-unused-vars
            const slider = new Swiper(selector, {
                loop: true,
                setWrapperSize: true,
                autoHeight: true,
                modules: [Navigation, Pagination],
                pagination: {
                    el: paginationSelector,
                    clickable: true,
                },
                navigation: {
                    nextEl: nextButton,
                    prevEl: prevButton,
                },
                breakpoints: {
                    1920: {
                        slidesPerView: 1
                    },
                },
            });
        });

        window.up1ItemGlideInitialized = true;
    }
});
